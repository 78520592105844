import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    window.addEventListener("resize", this.resize, false)
  }

  resize() {
    const container = document.querySelector(".attempt-content") as HTMLElement
    const targets = document.querySelectorAll(".card.answer.img") as NodeListOf<HTMLElement>

    const formulaWidth = container.offsetWidth / 3
    const formulaHeight = (container.offsetHeight * 0.75 / 2) - 20

    targets.forEach((target: HTMLElement) => {
      //target.style.width = formulaWidth > formulaHeight ? formulaWidth + "px" : formulaHeight + "px"
      target.style.maxWidth = formulaHeight < formulaWidth ? formulaHeight + "px" : formulaWidth + "px"
      //target.style.height = formulaWidth > formulaHeight ? formulaWidth + "px" : formulaHeight + "px"
      target.style.maxHeight = formulaHeight < formulaWidth ? formulaHeight + "px" : formulaWidth + "px"

      /*
      const image = target.querySelector("img") as HTMLImageElement
      image.style.width = formulaHeight < formulaWidth ? formulaHeight + "px" : formulaWidth + "px"
      image.style.height = formulaHeight < formulaWidth ? formulaHeight + "px" : formulaWidth + "px"
      */
    })
  }
}

