import { Controller } from "@hotwired/stimulus"
import { Toast } from "bootstrap"

export default class extends Controller {
  connect() {
    this.toastShow()
  }

  toastShow() {
    const toastContent = document.querySelector('.toast') as HTMLElement
    const toast = new Toast(toastContent)
    if (toast)
      toast.show()
  }
}
