import { Controller } from "@hotwired/stimulus"
import { Toast } from "bootstrap"

export default class extends Controller {
  connect() {
    const scrollableElement = document.querySelector(".scrollable") as HTMLElement
    const nextButton = document.querySelector(".btn.next-question") as HTMLInputElement
    const containerNextBtn = document.querySelector(".container-next-btn") as HTMLElement

    let status = scrollableElement.scrollTop + scrollableElement.offsetHeight + 20 >= scrollableElement.scrollHeight ? true : false

    // Always show the vertical scroll bar
    scrollableElement.style.overflowY = "scroll"
    // Disable the next button if not scrolled
    nextButton.disabled = !status

    scrollableElement.addEventListener("scroll", () => {
      status = scrollableElement.scrollTop + scrollableElement.offsetHeight + 20 >= scrollableElement.scrollHeight
      if (status) {
        nextButton.disabled = false
      }
    })

    containerNextBtn.addEventListener("click", () => {
      if (!status)
        this.toastShow()
    })
  }

  toastShow() {
    const toastContent = document.querySelector(".toast") as HTMLElement
    const toast = new Toast(toastContent)
    if (toast)
      toast.show()
  }
}
