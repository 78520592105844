import { Controller } from "@hotwired/stimulus"
import { getCSRFToken, createFetchHeaders } from "../../../utils/csrf"

export default class extends Controller {
    static targets = ["list"]

    static values = {
        values: Array
    }

    declare values: Array<{}>
    declare listTarget: HTMLUListElement

    load() {
        const csrfToken = getCSRFToken()
        const headers = createFetchHeaders(csrfToken)

        fetch(`/api/notifications`, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers,
        })
            .then(response => response.json())
            .then(data => {
                this.values = data
                this.setData()
            })
    }

    setData() {
        // Clear the children
        this.listTarget.replaceChildren()
        if (this.values.length > 0) {
            // Insert new notifications
            this.values.forEach(item => {
                const element = document.createElement('li')
                element.appendChild(this.createNotificationElement(item))
                element.classList.add('dropdown-item')
                this.listTarget.appendChild(element)
            })
        } else {
            const element = document.createElement('li')
            element.innerText = 'Pas de notifications'
            element.classList.add('dropdown-item')
            this.listTarget.appendChild(element)
        }
    }

    createNotificationElement(data: any) {
        const button = document.createElement('button')
        button.innerHTML = `<div class="d-flex flex-row flex-grow-1">${this.buildNotificationTitle(data.notification_type)}</div><div class="small">${data.time_ago}</div>`
        button.setAttribute('data-bs-toggle', 'modal')
        button.setAttribute('data-bs-target', '#notification-modal')
        button.classList.add('notification--button')
        button.addEventListener('click', () => {
            const modal = document.querySelector('#notification-modal')
            const body = modal?.querySelector('.modal-body')
            if (body) {
                body.innerHTML = data.data
                this.markAsRead(data.id)
            }
        })
        return button
    }

    buildNotificationTitle(notification_type: string) {
        switch (notification_type) {
            case "misc":
                return '<i class="icon misc"></i><div class="align-self-center">Divers</div>'
            case "assignment":
                return '<i class="icon assignment"></i><div class="align-self-center">Exercice reçu</div>'
            case "challenge":
                return '<i class="icon challenge"></i><div class="align-self-center">Challenge reçu</div>'
            case "achievement":
                return '<i class="icon achievement"></i><div class="align-self-center">Trophée débloqué</div>'
        }
    }

    markAsRead(notificationId: number) {
        const csrfToken = getCSRFToken()
        const headers = createFetchHeaders(csrfToken)

        fetch(`/api/notifications`, {
            method: 'PATCH',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers,
            body: JSON.stringify({ id: notificationId })
        })
            .then(response => response.json())
            .then()
    }
}