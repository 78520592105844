import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    clientY: Number,
    defaultHeight: Number
  }

  declare clientY: number | null
  declare defaultHeight: number

  connect() {
    const feedbackElement = document.querySelector('.question-feedback') as HTMLElement

    feedbackElement.addEventListener('touchstart', this.handleTouchStart, false)
    feedbackElement.addEventListener('touchmove', this.handleTouchMove, false)
    feedbackElement.addEventListener('click', this.toggleFeedback, false)

    this.clientY = null
    this.defaultHeight = feedbackElement.clientHeight
  }

  handleTouchStart(evt) {
    this.clientY = evt.touches[0].clientY
  }

  handleTouchMove(evt) {
    if (!this.clientY) {
      return
    }

    const feedbackElement = document.querySelector('.question-feedback') as HTMLElement
    const yUp = evt.touches[0].clientY
    const yDiff = this.clientY - yUp

    if (yDiff <= 0) {
      feedbackElement.classList.remove("pop-in")
      feedbackElement.classList.add("pop-out")
    }

    if (yDiff > 0) {
      feedbackElement.classList.remove("pop-out")
      feedbackElement.classList.add("pop-in")
    }

    this.clientY = null
  }

  toggleFeedback(evt) {
    const feedbackElement = document.querySelector('.question-feedback') as HTMLElement

    if (feedbackElement.classList.contains("pop-out")) {
      feedbackElement.classList.remove("pop-out")
      feedbackElement.classList.add("pop-in")
    } else {
      feedbackElement.classList.remove("pop-in")
      feedbackElement.classList.add("pop-out")
    }
  }
}

