import { Controller } from "@hotwired/stimulus"

const SELECTED_CLASS_NAME = "bg-orange"
const ANIMATION_CLASS = "loadanimation"

export default class extends Controller {
  static targets = ["checkbox", "form", "submit"]

  declare deckTarget: HTMLElement
  declare formTarget: HTMLFormElement
  declare submitTarget: HTMLInputElement

  connect() {
    if (this.submitTarget) {
      this.disableSubmit()
    }

    const formElements = document.querySelectorAll("input")
    formElements.forEach(element => element.checked = false)

    this.prepareAnimation()
  }

  prepareAnimation() {
    const toAnimate = document.querySelector('.translated') as HTMLElement
    toAnimate.classList.add(ANIMATION_CLASS)

    setTimeout(() => {
      this.animate()
    }, 100)
  }

  animate() {
    [...document.querySelectorAll(`.${ANIMATION_CLASS}`)]
      .map(e => e.classList.remove(ANIMATION_CLASS))
  }

  submitValidate(e: Event): boolean {
    const labels = this.formTarget.querySelectorAll<HTMLLabelElement>("label")
    const checked = Array.prototype.slice.call(labels).filter(el => el.classList.contains(SELECTED_CLASS_NAME))
    const answers = checked.map(el => el.querySelector("input").checked = true)

    if (answers.length > 0) {
      return true
    }

    return false
  }

  selectCheckbox(e: Event): void {
    const element = e.target as HTMLElement
    element.classList.toggle(SELECTED_CLASS_NAME)
    const labels = this.formTarget.querySelectorAll<HTMLLabelElement>("label")
    const checked = Array.prototype.slice.call(labels).filter(el => el.classList.contains(SELECTED_CLASS_NAME))

    if (checked.length > 0) {
      this.enableSubmit()
    } else {
      this.disableSubmit()
    }
  }

  selectRadio(e: Event): void {
    const element = e.target as HTMLElement
    const labels = this.formTarget.querySelectorAll<HTMLLabelElement>("label")
    labels.forEach(element => element.classList.remove(SELECTED_CLASS_NAME))
    const parent = element.closest("label") as HTMLLabelElement
    parent.classList.add(SELECTED_CLASS_NAME)

    this.enableSubmit()
  }

  enableSubmit(): void {
    if (this.submitTarget)
      this.submitTarget.disabled = false
  }

  disableSubmit(): void {
    if (this.submitTarget)
      this.submitTarget.disabled = true
  }
}
