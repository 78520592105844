import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  connect() {
    const modal = document.querySelector("#score-modal")
    if (modal) {
      const scoreModal = new Modal(modal, {})
      scoreModal.show()
    }

    const scoreElement = document.querySelector("#score-animation") as HTMLElement
    if (scoreElement) {
      const score = scoreElement.dataset.start
      const points = scoreElement.dataset.new
      if (score && points) {
        const start = parseInt(score)
        const end = start + parseInt(points)
        this.animateValue(scoreElement, start, end, 500)
      }
    }
  }

  animateValue(obj, start, end, duration) {
    let startTimestamp = 0

    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp
      const progress = Math.min((timestamp - startTimestamp) / duration, 1)
      obj.innerHTML = Math.floor(progress * (end - start) + start)

      if (progress < 1) {
        window.requestAnimationFrame(step)
      }
    };
    window.requestAnimationFrame(step)
  }
}
