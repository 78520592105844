import { Controller } from "@hotwired/stimulus"
import { Toast } from "bootstrap"

export default class extends Controller {
  connect(): void {
    const toastItem = document.querySelector('.toast') as HTMLElement
    const toast = new Toast(toastItem)
    if (toast)
      toast.show()
  }
}
